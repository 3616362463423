import Score from "react-apexcharts";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import isMobile from "is-mobile";
import { Bar } from "react-chartjs-2";
import { Button } from "components/Button";
import { HistoricItem } from "components/historicItem";
import { Icons } from "components/Icons";
import { ReportConsolidatedItem } from "components/ReportConsolidatedItem";
import { ScoreItem } from "components/ScoreItem";
import ReactLoading from "react-loading";
import {
  Block,
  BlockBody,
  BlockBodyContent,
  BlockHeader,
  Col,
  Row,
  Separator,
} from "styles";
import {
  getColor,
  getScoreColor,
  getScoreSlaughterColor,
  getScoreSlaughterText,
  getScoreText,
  getTotalScore,
} from "utils/globalFunctions";
import { useCallback, useState } from "react";
import { CreateEdit as CreateEditHistoric } from "../Connect/Broiler/Historic/CreateEdit";
import { Input } from "components/Input";
import { saveAs } from "file-saver";
import { api } from "services/api";
import { CreateEdit as CreateEditNecropsy } from "../Connect/Broiler/Necropsy/CreateEdit";
import { CreateEdit as CreateEditSlaughter } from "../Connect/Slaughter/Slaughter/CreateEdit";

export function Home({
  historicBroiler,
  graphLastMonth,
  getReportData,
  getReportlabels,
  consolidated,
  lastNecropsies,
  customer,
  location,
  consolidatedInput,
  setConsolidatedInput,
  uploading,
  uploadConsolidatedData,
  match,
  graphLastMonthSlaughter,
  getReportDataSlaughter,
  getReportlabelsSlaughter,
  lastSlaughters,
  consolidatedSlaughter,
  consolidatedSlaughterInput,
  setConsolidatedSlaughterInput,
  uploadConsolidatedSlaughterData,
  downloadReportConsolidatedSlaughter,
}) {
  const [showModalHistoric, setShowModalHistoric] = useState(false);

  const [showNecropsy, setShowNecropsy] = useState(false);

  const [necropsyId, setNecropsyId] = useState("");

  const [showSlaughter, setShowSlaughter] = useState(false);

  const [slaughterId, setSlaughterId] = useState("");

  const [historicId, setHistoricId] = useState(null);

  const [downloading, setDownloading] = useState(false);

  const downloadReportConsolidated = useCallback(async (item, index) => {
    const downloadingArray = [];
    downloadingArray[index] = true;
    setDownloading(downloadingArray);
    const response = await api.get(
      `broilerconsolidated/reportconsolidated/export/${item._id}`,
      {
        responseType: "arraybuffer",
      }
    );
    const blob = new Blob([response.data], {
      type: "application/excel",
    });
    saveAs(blob, `relatório de upload ${item.date}.xlsx`);
    setDownloading(false);
  }, []);

  return (
    <>
      {showNecropsy && (
        <CreateEditNecropsy
          show={showNecropsy}
          setShow={setShowNecropsy}
          location={location}
          customer={customer}
          id={necropsyId}
          match={match}
        />
      )}

      {showModalHistoric && (
        <CreateEditHistoric
          show={showModalHistoric}
          setShow={setShowModalHistoric}
          customer={customer._id}
          id={historicId}
          onHide={() => {
            setShowModalHistoric(false);
            setHistoricId(null);
          }}
          location={location}
        />
      )}
      {showSlaughter && (
        <CreateEditSlaughter
          show={showSlaughter}
          setShow={setShowSlaughter}
          location={location}
          customer={customer}
          id={slaughterId}
        />
      )}
      <>
        <Row>
          <Col className="animate__animated animate__fadeInRight">
            <Block>
              <BlockHeader bg="https://zenbytes-public.sfo2.digitaloceanspaces.com/mcassab/bg_header_nova_azul_wave_tall.png">
                Lesões por Score | Último Mês | Necropsias
                <div>
                  <Button
                    color="white"
                    bg="basf"
                    border="basf"
                    to={`/customer/connect/broiler/report/${customer._id}/1/30/-date/{}`}
                    style={{ marginRight: 15 }}
                  >
                    <Icons type="list" size="20" color="#fff" />
                  </Button>
                  <Button
                    color="white"
                    bg="default"
                    border="default"
                    to={`/customer/connect/broiler/report/${customer._id}/{}`}
                  >
                    <Icons type="plus" size="20" color="#fff" />
                  </Button>
                </div>
              </BlockHeader>
              <BlockBody>
                <BlockBodyContent>
                  {!!graphLastMonth.scores && (
                    <Bar
                      data={getReportData()}
                      options={{
                        labels: getReportlabels(),

                        elements: {
                          line: {
                            fill: false,
                          },
                        },
                        scales: {
                          xAxes: [
                            {
                              display: true,
                              gridLines: {
                                display: false,
                              },
                              stacked: true,
                              labels: getReportlabels(),
                            },
                          ],
                          yAxes: [
                            {
                              stacked: true,
                              type: "linear",
                              display: true,
                              position: "left",
                              id: "y-axis-1",
                              gridLines: {
                                display: false,
                              },
                              labels: {
                                show: true,
                              },
                              ticks: {
                                beginAtZero: true,
                                max: 10,
                              },
                            },
                            {
                              type: "linear",
                              display: true,
                              position: "right",
                              id: "y-axis-2",
                              gridLines: {
                                display: false,
                              },
                              labels: {
                                show: true,
                              },
                              ticks: {
                                beginAtZero: true,
                              },
                            },
                          ],
                        },
                        legend: {
                          position: "bottom",
                          display: false,
                        },
                        showAllTooltips: true,
                        tooltips: {
                          callbacks: {
                            label: function (tooltipItem, chart) {
                              const { label, data } =
                                chart.datasets[tooltipItem.datasetIndex];
                              return (
                                label +
                                ": " +
                                data[tooltipItem.index]
                                  .toString()
                                  .replace(".", ",") +
                                (label !== "Score-Connect (Digestivo)"
                                  ? "%"
                                  : "")
                              );
                            },
                            title: function (tooltipItem, data) {
                              return data.labels[tooltipItem[0].index];
                            },
                          },
                        },
                      }}
                    />
                  )}
                </BlockBodyContent>
              </BlockBody>
            </Block>
          </Col>
          <Col className="animate__animated animate__fadeInRight">
            <Block>
              <BlockHeader bg="https://zenbytes-public.sfo2.digitaloceanspaces.com/mcassab/bg-2-basf.jpg">
                Lesões por Score | Último Mês | Abate
                <div>
                  <Button
                    color="white"
                    bg="basf"
                    border="basf"
                    to={`/customer/connect/slaughter/report/${customer._id}/1/30/-date/{}`}
                    style={{ marginRight: 15 }}
                  >
                    <Icons type="list" size="20" color="#fff" />
                  </Button>
                  <Button
                    color="white"
                    bg="default"
                    border="default"
                    to={`/customer/connect/slaughter/report/${customer._id}/{}`}
                  >
                    <Icons type="plus" size="20" color="#fff" />
                  </Button>
                </div>
              </BlockHeader>
              <BlockBody>
                <BlockBodyContent>
                  {!!graphLastMonthSlaughter.data && (
                    <Bar
                      data={getReportDataSlaughter()}
                      options={{
                        labels: getReportlabelsSlaughter(),

                        elements: {
                          line: {
                            fill: false,
                          },
                        },
                        scales: {
                          xAxes: [
                            {
                              display: true,
                              gridLines: {
                                display: false,
                              },

                              labels: getReportlabelsSlaughter(),
                            },
                          ],
                          yAxes: [
                            {
                              type: "linear",
                              display: true,
                              position: "left",
                              id: "y-axis-1",
                              gridLines: {
                                display: false,
                              },
                              labels: {
                                show: true,
                              },
                              ticks: {
                                beginAtZero: true,
                                max: 10,
                              },
                            },
                            {
                              type: "linear",
                              display: true,
                              position: "right",
                              id: "y-axis-2",
                              gridLines: {
                                display: false,
                              },
                              labels: {
                                show: true,
                              },
                              ticks: {
                                beginAtZero: true,
                              },
                            },
                          ],
                        },
                        legend: {
                          position: "bottom",
                          display: false,
                        },
                        showAllTooltips: true,
                        tooltips: {
                          callbacks: {
                            label: function (tooltipItem, chart) {
                              const { label, data } =
                                chart.datasets[tooltipItem.datasetIndex];
                              return (
                                label +
                                ": " +
                                data[tooltipItem.index]
                                  .toString()
                                  .replace(".", ",")
                              );
                            },
                            title: function (tooltipItem, data) {
                              return data.labels[tooltipItem[0].index];
                            },
                          },
                        },
                      }}
                    />
                  )}
                </BlockBodyContent>
              </BlockBody>
            </Block>
          </Col>
        </Row>
        <Row>
          <Col className="animate__animated animate__fadeInRight">
            <Block>
              <BlockHeader>
                <span>Últimas necropsias</span>
                <div>
                  <Button
                    color="white"
                    bg="basf"
                    border="basf"
                    size="sm"
                    target="_blank"
                    style={{ marginRight: "10px" }}
                    href="https://zenbytes-public.sfo2.digitaloceanspaces.com/mcassab/Layout%20Connect%20-%20Planilha%20Necropsia.pdf"
                  >
                    <Icons type="download" size="20" color="#fff" />
                    &nbsp; Planilha Campo
                  </Button>
                  <Button
                    color="white"
                    bg="default"
                    border="default"
                    size="sm"
                    onClick={() => {
                      setNecropsyId("");
                      setShowNecropsy(true);
                    }}
                  >
                    <Icons type="plus" size="20" color="#fff" />
                    &nbsp; Nova Necropsia
                  </Button>
                </div>
              </BlockHeader>
              <BlockBody>
                {lastNecropsies.map((item) => (
                  <ScoreItem
                    key={item._id}
                    title={item.customer?.name}
                    scoreColor={getScoreColor(getTotalScore(item.birds))}
                    text={[item.integrated, item.incubatory]}
                    description={[
                      item.visitDate
                        ? `Visita ${format(
                            new Date(item.visitDate),
                            "dd MMM y",
                            {
                              locale: ptBR,
                            }
                          )}`
                        : null,
                      `${item.birds.length} aves`,
                      item.lineage,
                      item.age
                        ? `idade:
                      ${item.age}`
                        : null,
                    ]}
                    score={
                      <Score
                        options={{
                          plotOptions: {
                            radialBar: {
                              startAngle: -135,
                              endAngle: 225,
                              hollow: {
                                margin: 0,
                                size: "70%",
                                background: "#f9f9fc ",
                                image: undefined,
                                imageOffsetX: 0,
                                imageOffsetY: 0,
                                position: "front",
                              },
                              track: {
                                background: getColor(
                                  getScoreColor(getTotalScore(item.birds)),
                                  0.2
                                ),
                                strokeWidth: "67%",
                                margin: 0, // margin is in pixels
                              },

                              dataLabels: {
                                showOn: "always",
                                name: {
                                  offsetY: -5,
                                  show: true,
                                  color: getScoreColor(
                                    getTotalScore(item.birds)
                                  ),
                                  fontSize: "7px",
                                },
                                value: {
                                  formatter: function (val) {
                                    return (val / 10).toLocaleString();
                                  },
                                  color: getScoreColor(
                                    getTotalScore(item.birds)
                                  ),
                                  fontSize: "10px",
                                  show: true,
                                  offsetY: -5,
                                },
                              },
                            },
                          },
                          fill: {
                            colors: [getScoreColor(getTotalScore(item.birds))],
                            type: "gradient",
                            gradient: {
                              shade: "dark",
                              type: "horizontal",

                              gradientToColors: [
                                getScoreColor(getTotalScore(item.birds)),
                              ],
                              inverseColors: true,
                              opacityFrom: 1,
                              opacityTo: 0.8,
                              stops: [0, 100],
                            },
                          },
                          stroke: {
                            lineCap: "round",
                          },
                          labels: [getScoreText(getTotalScore(item.birds))],
                        }}
                        series={[getTotalScore(item.birds) * 10]}
                        type="radialBar"
                        width={isMobile() ? "90%" : "110"}
                      />
                    }
                    button={
                      <Button
                        bg={getScoreColor(getTotalScore(item.birds))}
                        border={getScoreColor(getTotalScore(item.birds))}
                        onClick={() => {
                          setNecropsyId(item._id);
                          setShowNecropsy(true);
                        }}
                        color="white"
                        size="sm"
                      >
                        <Icons type="etc" color="#fff" size="15" />
                      </Button>
                    }
                  />
                ))}
                {lastNecropsies[0] && (
                  <>
                    <Separator />
                    <Button
                      to={`/customer/connect/broiler/necropsy/1/30/-code/%7B%7D/${customer._id}`}
                      color="default"
                      bg="light"
                      border="light"
                      style={{ width: "100%" }}
                    >
                      Acessar Página de Necropsias&nbsp;
                      <Icons
                        type="angleDoubleRight"
                        size={20}
                        color="#009870"
                      />
                    </Button>
                  </>
                )}
              </BlockBody>
            </Block>
          </Col>

          <Col
            className="animate__animated animate__fadeInRight"
            style={{ minHeight: 170 }}
          >
            <Block>
              <BlockHeader bg="https://zenbytes-public.sfo2.digitaloceanspaces.com/mcassab/menu-top-connect.png">
                Últimos Abates
                <div>
                  <Button
                    color="white"
                    bg="basf"
                    border="basf"
                    size="sm"
                    target="_blank"
                    style={{ marginRight: "10px" }}
                    href="https://zenbytes-public.sfo2.digitaloceanspaces.com/mcassab/Layout%20Connect%20-%20Ficha%20de%20Abate.pdf"
                  >
                    <Icons type="download" size="20" color="#fff" />
                    &nbsp; Planilha Abate
                  </Button>
                  <Button
                    color="white"
                    bg="default"
                    border="default"
                    size="sm"
                    onClick={() => {
                      setSlaughterId("");
                      setShowSlaughter(true);
                    }}
                  >
                    <Icons type="plus" size="20" color="#fff" />
                    &nbsp; Novo Abate
                  </Button>
                </div>
              </BlockHeader>
              <BlockBody>
                {lastSlaughters.map((item) => (
                  <ScoreItem
                    key={item._id}
                    title={item.customer?.name}
                    scoreColor={getScoreSlaughterColor(
                      Math.round(item.total * 100) / 100
                    )}
                    text={[
                      item.responsible || "",
                      `Lote: ${item.batchCode || ""}`,
                    ]}
                    description={[
                      item.evaluationDate
                        ? `Avaliação ${format(
                            new Date(item.evaluationDate),
                            "dd MMM y",
                            {
                              locale: ptBR,
                            }
                          )}`
                        : null,
                    ]}
                    score={
                      <Score
                        options={{
                          plotOptions: {
                            radialBar: {
                              startAngle: -135,
                              endAngle: 225,
                              hollow: {
                                margin: 0,
                                size: "70%",
                                background: "#f9f9fc ",
                                image: undefined,
                                imageOffsetX: 0,
                                imageOffsetY: 0,
                                position: "front",
                              },
                              track: {
                                background: getColor(
                                  getScoreSlaughterColor(
                                    Math.round(item.total * 100) / 100
                                  ),
                                  0.2
                                ),
                                strokeWidth: "67%",
                                margin: 0, // margin is in pixels
                              },

                              dataLabels: {
                                showOn: "always",
                                name: {
                                  offsetY: -5,
                                  show: true,
                                  color: getScoreSlaughterColor(
                                    Math.round(item.total * 100) / 100
                                  ),
                                  fontSize:
                                    getScoreSlaughterText(
                                      Math.round(item.total * 100) / 100
                                    ) === "Muita Atenção"
                                      ? "5px"
                                      : "7px",
                                },
                                value: {
                                  formatter: function (val) {
                                    return (
                                      Math.round(val * 100) /
                                      100 /
                                      10
                                    ).toLocaleString();
                                  },
                                  color: getScoreSlaughterColor(
                                    Math.round(item.total * 100) / 100
                                  ),
                                  fontSize: "10px",
                                  show: true,
                                  offsetY: -5,
                                },
                              },
                            },
                          },
                          fill: {
                            colors: [
                              getScoreSlaughterColor(
                                Math.round(item.total * 100) / 100
                              ),
                            ],
                            type: "gradient",
                            gradient: {
                              shade: "dark",
                              type: "horizontal",

                              gradientToColors: [
                                getScoreSlaughterColor(
                                  Math.round(item.total * 100) / 100
                                ),
                              ],
                              inverseColors: true,
                              opacityFrom: 1,
                              opacityTo: 0.8,
                              stops: [0, 100],
                            },
                          },
                          stroke: {
                            lineCap: "round",
                          },
                          labels: [
                            getScoreSlaughterText(
                              Math.round(item.total * 100) / 100
                            ),
                          ],
                        }}
                        series={[(Math.round(item.total * 100) / 100) * 10]}
                        type="radialBar"
                        width={isMobile() ? "90%" : "110"}
                      />
                    }
                    button={
                      <Button
                        bg={getScoreSlaughterColor(
                          Math.round(item.total * 100) / 100
                        )}
                        border={getScoreSlaughterColor(
                          Math.round(item.total * 100) / 100
                        )}
                        onClick={() => {
                          setSlaughterId(item._id);
                          setShowSlaughter(true);
                        }}
                        color="white"
                        size="sm"
                      >
                        <Icons type="etc" color="#fff" size="15" />
                      </Button>
                    }
                  />
                ))}
                {lastSlaughters[0] && (
                  <>
                    <Separator />
                    <Button
                      to={`/customer/connect/slaughter/1/30/-code/%7B%7D/${customer._id}`}
                      color="default"
                      bg="light"
                      border="light"
                      style={{ width: "100%" }}
                    >
                      Acessar Página de Abates&nbsp;
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        width="18px"
                        height="18px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          strokeWidth={1}
                          fill="none"
                          fillRule="evenodd"
                        >
                          <polygon points="0 0 24 0 24 24 0 24" />
                          <path
                            d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                            fill="#009870"
                            fillRule="nonzero"
                          />
                          <path
                            d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                            fill="#009870"
                            fillRule="nonzero"
                            opacity="0.3"
                            transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                          />
                        </g>
                      </svg>
                    </Button>
                  </>
                )}
              </BlockBody>
            </Block>
          </Col>
        </Row>
        <Row>
          <Col className="animate__animated animate__fadeInLeft">
            <Block>
              <BlockHeader>
                <span>
                  <Icons type="broiler" size="20" color="#009870" />
                  &nbsp;Histórico de Programas
                </span>
                <Button
                  color="white"
                  bg="default"
                  border="default"
                  size="sm"
                  onClick={() => {
                    setHistoricId(null);
                    setShowModalHistoric(true);
                  }}
                >
                  <Icons type="substract" size="20" color="#fff" />
                  Programa Atual
                </Button>
              </BlockHeader>
              <BlockBody>
                {historicBroiler.map((item) => (
                  <HistoricItem
                    key={item._id}
                    {...item}
                    button={
                      <Button
                        bg={`var(--default)`}
                        border={`var(--default)`}
                        color="white"
                        size="sm"
                        onClick={() => {
                          setHistoricId(item._id);
                          setShowModalHistoric(true);
                        }}
                      >
                        <Icons type="etc" color="#fff" size="15" />
                      </Button>
                    }
                  />
                ))}
                <Separator />
                <Button
                  color="default"
                  bg="light"
                  border="light"
                  style={{ width: "100%" }}
                  to={`/customer/connect/broiler/historic/1/30/index/{}/${customer._id}`}
                >
                  Acessar Histórico Completo&nbsp;
                  <Icons type="angleDoubleRight" size={20} color="#009870" />
                </Button>
              </BlockBody>
            </Block>
          </Col>
        </Row>
        <Row>
          <Col lassName="animate__animated animate__fadeInLeft">
            <Block>
              <BlockHeader>
                <span>Consolidado Frango de Corte</span>
                <Button
                  color="white"
                  bg="basf"
                  border="basf"
                  size="sm"
                  href="https://zenbytes-public.sfo2.digitaloceanspaces.com/mcassab/Modelo_Upload_Dados.xlsx"
                >
                  <Icons type="download" size="20" color="#fff" />
                  &nbsp; Planilha Modelo
                </Button>
              </BlockHeader>
              <BlockBody>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Input
                    type="inputFile"
                    disabled={uploading}
                    item={consolidatedInput}
                    setItem={setConsolidatedInput}
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    placeholder="Selecione o arquivo para upload"
                  />
                  <Button
                    color="white"
                    bg="default"
                    border="default"
                    onClick={uploadConsolidatedData}
                    disabled={!consolidatedInput.files[0]}
                    style={{ marginLeft: "10px" }}
                  >
                    <Icons type="cloudUpload" size={18} color="#fff" />
                  </Button>
                </div>

                <Separator />
                {consolidated.map((item, index) => (
                  <ReportConsolidatedItem
                    key={item._id}
                    {...item}
                    button={
                      <Button
                        bg={`var(--default)`}
                        border={`var(--default)`}
                        color="white"
                        size="sm"
                        disabled={downloading[index]}
                        onClick={() => downloadReportConsolidated(item, index)}
                      >
                        {downloading[index] ? (
                          <ReactLoading
                            type="spin"
                            color="#ffffff"
                            height={14}
                            width={14}
                          />
                        ) : (
                          <Icons type="download" color="#fff" size="15" />
                        )}
                      </Button>
                    }
                  />
                ))}
                <Separator />
                <Button
                  color="default"
                  bg="light"
                  border="light"
                  style={{ width: "100%" }}
                  to={`/customer/connect/broiler/consolidated/1/30/-date/{}/${customer._id}/1`}
                >
                  Acessar Página de Uploads&nbsp;
                  <Icons type="angleDoubleRight" size={20} color="#009870" />
                </Button>
              </BlockBody>
            </Block>
          </Col>
          <Col className="animate__animated animate__fadeInLeft">
            <Block>
              <BlockHeader>
                Dados SIF
                {/* <Button
                  color="white"
                  bg="basf"
                  border="basf"
                  size="sm"
                  href="https://zenbytes-public.sfo2.digitaloceanspaces.com/mcassab/Modelo_Upload_Dados_Abate.xlsx"
                >
                  <Icons type="download" size="20" color="#fff" />
                  &nbsp; Planilha Modelo
                </Button> */}
              </BlockHeader>
              <BlockBody>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Input
                    type="inputFile"
                    disabled={uploading}
                    item={consolidatedSlaughterInput}
                    setItem={setConsolidatedSlaughterInput}
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    placeholder="Selecione o arquivo para upload"
                  />
                  <Button
                    color="white"
                    bg="default"
                    border="default"
                    onClick={uploadConsolidatedSlaughterData}
                    disabled={!consolidatedSlaughterInput.files[0]}
                    style={{ marginLeft: "10px" }}
                  >
                    <Icons type="cloudUpload" size={18} color="#fff" />
                  </Button>
                </div>

                <Separator />
                {consolidatedSlaughter.map((item, index) => (
                  <ReportConsolidatedItem
                    key={item._id}
                    {...item}
                    button={
                      <Button
                        bg={`var(--default)`}
                        border={`var(--default)`}
                        color="white"
                        size="sm"
                        disabled={downloading[index]}
                        onClick={() =>
                          downloadReportConsolidatedSlaughter(item, index)
                        }
                      >
                        {downloading[index] ? (
                          <ReactLoading
                            type="spin"
                            color="#ffffff"
                            height={14}
                            width={14}
                          />
                        ) : (
                          <Icons type="download" color="#fff" size="15" />
                        )}
                      </Button>
                    }
                  />
                ))}
                <Separator />
                <Button
                  color="default"
                  bg="light"
                  border="light"
                  style={{ width: "100%" }}
                  to={`/customer/connect/slaughter/consolidated/1/30/-date/{}/${customer._id}/1`}
                >
                  Acessar Página de Uploads&nbsp;
                  <Icons type="angleDoubleRight" size={20} color="#009870" />
                </Button>
              </BlockBody>
            </Block>
          </Col>
        </Row>
      </>
    </>
  );
}

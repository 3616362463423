import { Route, Switch, Redirect } from "react-router-dom";
import { Home } from "connect/App/Views/Home";
import { Container, Content } from "./style";
import { NotFound } from "connect/App/Views/NotFound";
import { Customers } from "connect/App/Views/Customers";
import { Profile } from "connect/App/Views/Profile";
import { Password } from "connect/App/Views/Password";
import { CreateEdit as BasicRegistrationCustomersCreateEdit } from "connect/App/Views/BasicRegistration/Customers/CreateEdit";
import { List as BasicRegistrationCustomersList } from "connect/App/Views/BasicRegistration/Customers/List";
import { CreateEdit as BasicRegistrationProfilesCreateEdit } from "connect/App/Views/BasicRegistration/Profiles/CreateEdit";
import { List as BasicRegistrationProfilesList } from "connect/App/Views/BasicRegistration/Profiles/List";
import { CreateEdit as BasicRegistrationUsersCreateEdit } from "connect/App/Views/BasicRegistration/Users/CreateEdit";
import { List as BasicRegistrationUsersList } from "connect/App/Views/BasicRegistration/Users/List";
import { Necropsy as ConfigConnectBroilerNecropsy } from "connect/App/Views/Config/Connect/Broiler/Necropsy";
import { Necropsy as ConfigConnectHeavyBroilerNecropsy } from "connect/App/Views/Config/Connect/HeavyBroiler/Necropsy";
import { PreNecropsy as ConfigConnectHeavyBroilerPreNecropsy } from "connect/App/Views/Config/Connect/HeavyBroiler/PreNecropsy";
import { Slaughter as ConfigConnectSlaughter } from "connect/App/Views/Config/Connect/Slaughter";
import { Factory as ConfigConnectFactory } from "connect/App/Views/Config/Connect/Factory";
import { CreateRecreate as ConfigMeggCreateRecreate } from "connect/App/Views/Config/Megg/CreateRecreate";
import { Production as ConfigMeggProduction } from "connect/App/Views/Config/Megg/Production";
import { List as CustomerConnectBroilerReportList } from "connect/App/Views/Customer/Connect/Broiler/Report/List";
import { List as CustomerConnectBroilerHistoricList } from "connect/App/Views/Customer/Connect/Broiler/Historic/List";
import { List as CustomerConnectBroilerConsolidatedList } from "connect/App/Views/Customer/Connect/Broiler/Consolidated/List";
import { List as CustomerConnectBroilerNecropsyList } from "connect/App/Views/Customer/Connect/Broiler/Necropsy/List";
import { Report as CustomerConnectBroilerReport } from "connect/App/Views/Customer/Connect/Broiler/Report/CreateReport";
import { NewReport as CustomerConnectBroilerNewReport } from "connect/App/Views/Customer/Connect/Broiler/Report/NewReport";
import { List as CustomerConnectHeavyBroilerHistoricList } from "connect/App/Views/Customer/Connect/HeavyBroiler/Historic/List";
import { List as CustomerConnectHeavyBroilerPreNecropsyList } from "connect/App/Views/Customer/Connect/HeavyBroiler/PreNecropsy/List";
import { Report as CustomerConnectHeavyBroilerReport } from "connect/App/Views/Customer/Connect/HeavyBroiler/Report";
import { List as CustomerConnectSlaughterChecklistList } from "connect/App/Views/Customer/Connect/Slaughter/Checklist/List";
import { List as CustomerConnectSlaughterConsolidatedList } from "connect/App/Views/Customer/Connect/Slaughter/Consolidated/List";
import { List as CustomerConnectSlaughterSifList } from "connect/App/Views/Customer/Connect/Slaughter/Sif/List";
import { List as CustomerConnectSlaughterReportList } from "connect/App/Views/Customer/Connect/Slaughter/Report/List";
import { List as CustomerConnectSlaughterList } from "connect/App/Views/Customer/Connect/Slaughter/Slaughter/List";
import { Report as CustomerConnectSlaughterReport } from "connect/App/Views/Customer/Connect/Slaughter/Report";
import { CreateEdit as CustomerFactoryCreateEdit } from "connect/App/Views/Customer/Factory/CreateEdit";
import { List as CustomerFactoryList } from "connect/App/Views/Customer/Factory/List";
import { List as CustomerMeggCreateRecreateHistoricList } from "connect/App/Views/Customer/Megg/CreateRecreate/Historic/List";
import { List as CustomerMeggCreateRecreateNecropsyList } from "connect/App/Views/Customer/Megg/CreateRecreate/Necropsy/List";
import { List as CustomerMeggCreateProductionList } from "connect/App/Views/Customer/Megg/Production/Historic/List";
import { List as CustomerMeggCreateProductionPreNecropsyList } from "connect/App/Views/Customer/Megg/Production/PreNecropsy/List";
import { Customer } from "../Views/Customer";

export function Routes() {
  return (
    <Container>
      <Content>
        <Switch>
          <Route
            path="/"
            exact={true}
            render={(props) => <Redirect to={`home`} />}
          ></Route>
          <Route path="/home" render={(props) => <Home {...props} />}></Route>

          {/* BasicRegistration - Customers */}
          <Route
            path="/basicregistration/customer/create"
            render={(props) => (
              <BasicRegistrationCustomersCreateEdit {...props} />
            )}
          />
          <Route
            path="/basicregistration/customer/edit/:id"
            render={(props) => (
              <BasicRegistrationCustomersCreateEdit {...props} />
            )}
          />
          <Route
            path="/basicregistration/customer/:page/:limit/:sort/:query"
            render={(props) => <BasicRegistrationCustomersList {...props} />}
          />
          {/* BasicRegistration - Profiles */}
          <Route
            path="/basicregistration/profile/create"
            render={(props) => (
              <BasicRegistrationProfilesCreateEdit {...props} />
            )}
          />
          <Route
            path="/basicregistration/profile/edit/:id"
            render={(props) => (
              <BasicRegistrationProfilesCreateEdit {...props} />
            )}
          />
          <Route
            path="/basicregistration/profile/:page/:limit/:sort/:query"
            render={(props) => <BasicRegistrationProfilesList {...props} />}
          />
          {/* BasicRegistration - Users */}
          <Route
            path="/basicregistration/user/create"
            render={(props) => <BasicRegistrationUsersCreateEdit {...props} />}
          />
          <Route
            path="/basicregistration/user/edit/:id"
            render={(props) => <BasicRegistrationUsersCreateEdit {...props} />}
          />
          <Route
            path="/basicregistration/user/:page/:limit/:sort/:query"
            render={(props) => <BasicRegistrationUsersList {...props} />}
          />

          {/* Config - Connect - Broiler */}
          <Route
            path="/config/connect/broiler/necropsy"
            render={(props) => <ConfigConnectBroilerNecropsy {...props} />}
          />
          {/* Config - Connect - HeavyBroiler */}
          <Route
            path="/config/connect/heavybroiler/necropsy"
            render={(props) => <ConfigConnectHeavyBroilerNecropsy {...props} />}
          />
          <Route
            path="/config/connect/heavybroiler/prenecropsy"
            render={(props) => (
              <ConfigConnectHeavyBroilerPreNecropsy {...props} />
            )}
          />
          {/* Config - Connect - Slaughter */}
          <Route
            path="/config/connect/slaughter"
            render={(props) => <ConfigConnectSlaughter {...props} />}
          />
          <Route
            path="/config/connect/factory/:page/:limit"
            render={(props) => <ConfigConnectFactory {...props} />}
          />

          {/* Config - Megg - CreateRecreate */}
          <Route
            path="/config/megg/createrecreate"
            render={(props) => <ConfigMeggCreateRecreate {...props} />}
          />
          {/* Config - Megg - Production */}
          <Route
            path="/config/megg/production"
            render={(props) => <ConfigMeggProduction {...props} />}
          />

          {/* Customer - Connect - Broiler */}
          <Route
            path="/customer/connect/broiler/report/:id/:page/:limit/:sort/:query"
            render={(props) => <CustomerConnectBroilerReportList {...props} />}
          />
          <Route
            path="/customer/connect/broiler/report/:id/:query/:reportId"
            render={(props) => <CustomerConnectBroilerReport {...props} />}
          />
          <Route
            path="/customer/connect/broiler/report/:id/:query"
            render={(props) => <CustomerConnectBroilerReport {...props} />}
          />

          <Route
            path="/customer/connect/broiler/report/:id"
            render={(props) => <CustomerConnectBroilerReport {...props} />}
          />

          {/* New Report */}

          <Route
            path="/customer/connect/broiler/newreport/:id/:query/:reportId"
            render={(props) => <CustomerConnectBroilerNewReport {...props} />}
          />
          <Route
            path="/customer/connect/broiler/newreport/:id/:query"
            render={(props) => <CustomerConnectBroilerNewReport {...props} />}
          />

          <Route
            path="/customer/connect/broiler/newreport/:id"
            render={(props) => <CustomerConnectBroilerNewReport {...props} />}
          />

          <Route
            path="/customer/connect/broiler/historic/:page/:limit/:sort/:query/:id"
            render={(props) => (
              <CustomerConnectBroilerHistoricList {...props} />
            )}
          />

          <Route
            path="/customer/connect/broiler/consolidated/:page/:limit/:sort/:query/:id/:tab"
            render={(props) => (
              <CustomerConnectBroilerConsolidatedList {...props} />
            )}
          />

          <Route
            path="/customer/connect/broiler/necropsy/:page/:limit/:sort/:query/:id"
            render={(props) => (
              <CustomerConnectBroilerNecropsyList {...props} />
            )}
          />
          <Route
            path="/customer/connect/broiler/necropsy/:page/:limit/:sort/:query"
            render={(props) => (
              <CustomerConnectBroilerNecropsyList {...props} />
            )}
          />
          {/* Customer - Connect - HeavyBroiler */}
          <Route
            path="/customer/connect/heavybroiler/historic/:page/:limit/:sort/:query/:id"
            render={(props) => (
              <CustomerConnectHeavyBroilerHistoricList {...props} />
            )}
          />

          <Route
            path="/customer/connect/heavybroiler/prenecropsy/:page/:limit/:sort/:query/:id"
            render={(props) => (
              <CustomerConnectHeavyBroilerHistoricList {...props} />
            )}
          />

          <Route
            path="/customer/connect/heavybroiler/prenecropsy/:page/:limit/:sort/:query"
            render={(props) => (
              <CustomerConnectHeavyBroilerPreNecropsyList {...props} />
            )}
          />

          <Route
            path="/customer/connect/heavybroiler/report/:id/:query"
            render={(props) => <CustomerConnectHeavyBroilerReport {...props} />}
          />
          <Route
            path="/customer/connect/heavybroiler/report/:id"
            render={(props) => <CustomerConnectHeavyBroilerReport {...props} />}
          />
          {/* Customer - Connect - Slaughter */}

          <Route
            path="/customer/connect/slaughter/historic/:page/:limit/:sort/:query/:id"
            render={(props) => (
              <CustomerConnectBroilerHistoricList {...props} />
            )}
          />
          <Route
            path="/customer/connect/slaughter/report/:id/:page/:limit/:sort/:query"
            render={(props) => (
              <CustomerConnectSlaughterReportList {...props} />
            )}
          />
          <Route
            path="/customer/connect/slaughter/report/:id/:query/:reportId"
            render={(props) => <CustomerConnectSlaughterReport {...props} />}
          />
          <Route
            path="/customer/connect/slaughter/report/:id/:query"
            render={(props) => <CustomerConnectSlaughterReport {...props} />}
          />
          <Route
            path="/customer/connect/slaughter/report/:id"
            render={(props) => <CustomerConnectSlaughterReport {...props} />}
          />

          <Route
            path="/customer/connect/slaughter/consolidated/:page/:limit/:sort/:query/:id/:tab"
            render={(props) => (
              <CustomerConnectSlaughterConsolidatedList {...props} />
            )}
          />
          <Route
            path="/customer/connect/slaughter/sif/:page/:limit/:sort/:query/:id/:tab"
            render={(props) => <CustomerConnectSlaughterSifList {...props} />}
          />

          <Route
            path="/customer/connect/slaughter/checklist/:page/:limit/:sort/:query/:id"
            render={(props) => (
              <CustomerConnectSlaughterChecklistList {...props} />
            )}
          />
          <Route
            path="/customer/connect/slaughter/checklist/:page/:limit/:sort/:query"
            render={(props) => (
              <CustomerConnectSlaughterChecklistList {...props} />
            )}
          />
          <Route
            path="/customer/connect/slaughter/:page/:limit/:sort/:query/:id"
            render={(props) => <CustomerConnectSlaughterList {...props} />}
          />
          <Route
            path="/customer/connect/slaughter/:page/:limit/:sort/:query"
            render={(props) => <CustomerConnectSlaughterList {...props} />}
          />
          {/* Customer - Factory */}
          <Route
            path="/customer/factory/:customer/:page/:limit/:sort/:query/:tab/:id"
            render={(props) => <CustomerFactoryCreateEdit {...props} />}
          />
          <Route
            path="/customer/factory/:customer/:page/:limit/:sort/:query/:tab"
            render={(props) => <CustomerFactoryCreateEdit {...props} />}
          />
          <Route
            path="/customer/factory/:page/:limit/:sort/:query/:id"
            render={(props) => <CustomerFactoryList {...props} />}
          />
          {/* Customer - Factory - CreateRecreate */}
          <Route
            path="/customer/megg/createrecreate/historic/:page/:limit/:sort/:query/:id"
            render={(props) => (
              <CustomerMeggCreateRecreateHistoricList {...props} />
            )}
          />
          <Route
            path="/customer/megg/createrecreate/necropsy/:page/:limit/:sort/:query/:id"
            render={(props) => (
              <CustomerMeggCreateRecreateNecropsyList {...props} />
            )}
          />
          {/* Customer - Factory - Production */}
          <Route
            path="/customer/megg/production/historic/:page/:limit/:sort/:query/:id"
            render={(props) => <CustomerMeggCreateProductionList {...props} />}
          />
          <Route
            path="/customer/megg/production/prenecropsy/:page/:limit/:sort/:query/:id"
            render={(props) => (
              <CustomerMeggCreateProductionPreNecropsyList {...props} />
            )}
          />
          {/* Customer */}
          <Route
            path="/customer/:id/:tab"
            render={(props) => <Customer {...props} />}
          />
          <Route
            path="/customer/:id"
            render={(props) => <Customer {...props} />}
          />

          {/* Customers */}
          <Route
            path="/customers/:page/:limit/:sort/:query"
            render={(props) => <Customers {...props} />}
          />

          <Route path="/profile" render={(props) => <Profile {...props} />} />
          <Route path="/password" render={(props) => <Password {...props} />} />
          <Route path="/404" render={(props) => <NotFound {...props} />} />
          <Route path="*" render={(props) => <NotFound {...props} />} />
        </Switch>
      </Content>
    </Container>
  );
}

import React, { useState, useEffect, useCallback } from "react";
import { saveAs } from "file-saver";
import { api } from "services/api";
import { SubHeader } from "components/SubHeader";
import { Icons } from "components/Icons";
import { Col, Row } from "styles";
import { AnimatedButton } from "components/AnimatedButton";
import { Table } from "components/Table";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";

export function List(props) {
  const tableParamsData = [
    {
      key: "date",
      label: "Data",
      filter: "date",
      type: "date",
      mobile: {
        type: "title",
      },
    },
    {
      key: "lot",
      label: "Identificação do núcleo (Lote para abate)",
      filter: "texto",
      placeholder: "Lote",
      mobile: {
        type: "title",
      },
    },
    {
      key: "totalSlaughter",
      label: "Número Aves Abatidas",
      placeholder: "Número Aves Abatidas",
      mobile: {
        type: "item",
      },
    },
  ];

  const tableParamsUploads = [
    {
      key: "date",
      label: "Data",
      filter: "date",
      type: "custom",
      mobile: {
        type: "title",
      },
      response: ({ date }) =>
        format(new Date(date), "dd ' de ' MMMM', 'yyyy',' H:mm", {
          locale: ptBR,
        }),
    },

    {
      key: "inserts",
      label: "Dados Inseridos",
      params: "count",
      mobile: {
        type: "item",
        label: true,
      },
    },
    {
      key: "user",
      label: "Usuário",
      params: "name",
      filter: "text",
      placeholder: "Nome",
      mobile: {
        type: "title",
      },
    },
  ];

  const [customerName, setCustomerName] = useState("");

  const [downloading, setDownloading] = useState(false);

  const setTab = useCallback(
    (index) => {
      props.history.push(
        props.match.path
          .replace(":page", 1)
          .replace(":limit", props.match.params.limit)
          .replace(":sort", props.match.params.sort)
          .replace(":query", props.match.params.query)
          .replace(":id", props.match.params.id)
          .replace(":tab", index)
      );
    },
    [props]
  );

  const downloadReport = useCallback(async (item, index) => {
    const downloadingArray = [];
    downloadingArray[index] = true;
    setDownloading(downloadingArray);
    const response = await api.get(`sif/report/export/${item._id}`, {
      responseType: "arraybuffer",
    });
    const blob = new Blob([response.data], {
      type: "application/excel",
    });
    saveAs(blob, `relatório de upload ${item.date}.xlsx`);
    setDownloading(false);
  }, []);

  useEffect(() => {
    const getCustomerName = async () => {
      const response = await api.get(
        `/connect/customer/${props.match.params.id}`
      );
      setCustomerName(response.data.name);
    };
    getCustomerName();
  }, [props.match.params.id]);

  return (
    <>
      <SubHeader
        {...props}
        title={customerName}
        route="broiler"
        breadcrumbs={[
          {
            label: customerName,
            to: "/connect/customer/" + props.match.params.id,
          },
          {
            label: "Frango de Corte",
          },
          { label: "Dados SIF Abate" },
        ]}
        icon={<Icons type="cloudUpload" size="24" color="#fff" />}
      />
      <Row>
        <Col className="animate__animated animate__fadeInLeft">
          <AnimatedButton
            icon={<Icons type="list" size={50} color="#009870" />}
            title="Dados"
            text="Dados SIF"
            onClick={() => setTab(0)}
            active={+props.match.params.tab === 0}
          />
        </Col>
        <Col className="animate__animated animate__fadeInRight">
          <AnimatedButton
            icon={<Icons type="cloudUpload" size={50} color="#009870" />}
            title="Uploads"
            text="Relatório de uploads"
            onClick={() => setTab(1)}
            active={+props.match.params.tab === 1}
          />
        </Col>
        <Col className="animate__animated animate__fadeInRight"></Col>
      </Row>
      {+props.match.params.tab === 0 && (
        <Table
          cols={tableParamsData}
          emptyText={"Nenhum Dados localizado!"}
          route={`sif/${props.match.params.id}`}
          noEdit={true}
          {...props}
        />
      )}
      {+props.match.params.tab === 1 && (
        <Table
          cols={tableParamsUploads}
          emptyText={"Nenhum Upload localizado!"}
          editHandler={downloadReport}
          loadingEdit={downloading}
          route={`sif/report/${props.match.params.id}`}
          customEditIcon="download"
          customEditText="Baixar"
          {...props}
        />
      )}
    </>
  );
}

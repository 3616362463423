import { Divider, Tab, Tabs } from "@material-ui/core";
import { Input } from "components/Input";
import { Body, Header } from "components/Table/style";
import { useEffect, useState } from "react";
import { Block, BlockBody, BlockHeader, Col, Row } from "styles";
import { Table } from "../Connect/Broiler/Historic/CreateEdit/style";
import Swal from "sweetalert2";
import { api } from "services/api";
import { Button } from "components/Button";
import { Icons } from "components/Icons";

export const Config = (props) => {
  const [tab, setTab] = useState(0);
  const [loadSifConfig, setLoadSifConfig] = useState(true);

  const [sifConfig, setSifConfig] = useState({
    lot: "",
    totalSlaughter: "",
    header: "",
    date: "",
    aerosaculiteTotalCondemnation: "",
    aerosaculitePartialCondemnation: "",
    aerosaculiteConditionalUse: "",
    hemorrhagesTotalCondemnation: "",
    hemorrhagesPartialCondemnation: "",
    hemorrhagesConditionalUse: "",
    joints1TotalCondemnation: "",
    joints1PartialCondemnation: "",
    joints1ConditionalUse: "",
    joints2TotalCondemnation: "",
    joints2PartialCondemnation: "",
    joints2ConditionalUse: "",
    disgustingAppearanceTotalCondemnation: "",
    disgustingAppearancePartialCondemnation: "",
    disgustingAppearanceConditionalUse: "",
    cannibalismTotalCondemnation: "",
    cannibalismPartialCondemnation: "",
    cannibalismConditionalUse: "",
    cachexiaTotalCondemnation: "",
    cachexiaPartialCondemnation: "",
    cachexiaConditionalUse: "",
    celluliteTotalCondemnation: "",
    cellulitePartialCondemnation: "",
    celluliteConditionalUse: "",
    gastrointestinalContaminationTotalCondemnation: "",
    gastrointestinalContaminationPartialCondemnation: "",
    gastrointestinalContaminationConditionalUse: "",
    nonGastrointestinalContaminationTotalCondemnation: "",
    nonGastrointestinalContaminationPartialCondemnation: "",
    nonGastrointestinalContaminationConditionalUse: "",
    scaldedAliveTotalCondemnation: "",
    scaldedAlivePartialCondemnation: "",
    scaldedAliveConditionalUse: "",
    technologicalFailuresTotalCondemnation: "",
    technologicalFailuresPartialCondemnation: "",
    technologicalFailuresConditionalUse: "",
    skinInjuryTotalCondemnation: "",
    skinInjuryPartialCondemnation: "",
    skinInjuryConditionalUse: "",
    inflammatoryInjuryTotalCondemnation: "",
    inflammatoryInjuryPartialCondemnation: "",
    inflammatoryInjuryConditionalUse: "",
    traumaticInjuryTotalCondemnation: "",
    traumaticInjuryPartialCondemnation: "",
    traumaticInjuryConditionalUse: "",
    thinnessTotalCondemnation: "",
    thinnessPartialCondemnation: "",
    thinnessConditionalUse: "",
    neoplasmTotalCondemnation: "",
    neoplasmPartialCondemnation: "",
    neoplasmConditionalUse: "",
    septicemiaTotalCondemnation: "",
    septicemiaPartialCondemnation: "",
    septicemiaConditionalUse: "",
    asciticSyndromeTotalCondemnation: "",
    asciticSyndromePartialCondemnation: "",
    asciticSyndromeConditionalUse: "",
    abnormalStatesTotalCondemnation: "",
    abnormalStatesPartialCondemnation: "",
    abnormalStatesConditionalUse: "",
    totalTotalCondemnation: "",
    totalPartialCondemnation: "",
    totalConditionalUse: "",
  });

  const tableConfig = [
    { param: "aerosaculite", label: "AEROSSACULITE" },
    { param: "hemorrhages", label: "ALTERAÇÕES MUSCULARES(hemorragias)" },
    { param: "joints1", label: "ARTRITE/TENOSINOVITE (1 articulação)" },
    { param: "joints2", label: "ARTRITE/TENOSINOVITE (2 articulações)" },
    { param: "disgustingAppearance", label: "ASPECTO REPUGNANTE" },
    { param: "cannibalism", label: "CANIBALISMO" },
    { param: "cachexia", label: "CAQUEXIA" },
    { param: "cellulite", label: "CELULITE" },
    {
      param: "gastrointestinalContamination",
      label: "CONTAMINAÇÃO GASTROINTESTINAL E BILIAR",
    },
    {
      param: "nonGastrointestinalContamination",
      label: "CONTAMINAÇÃO NÃO GASTROINTESTINAL",
    },
    { param: "scaldedAlive", label: "ESCALDADO VIVO (sem corte da sangria)" },
    {
      param: "technologicalFailures",
      label:
        "FALHAS TECNOLÓGICAS (inclusive má sangria e fraturas post mortem generalizadas)",
    },
    { param: "skinInjury", label: "LESÃO DE PELE" },
    { param: "inflammatoryInjury", label: "LESÃO INFLAMATÓRIA" },
    { param: "traumaticInjury", label: "LESÃO TRAUMÁTICA" },
    { param: "thinness", label: "MAGREZA" },
    { param: "neoplasm", label: "NEOPLASIA" },
    { param: "septicemia", label: "SEPTICEMIA" },
    { param: "asciticSyndrome", label: "SINDROME ASCÍTICA" },
    {
      param: "abnormalStates",
      label: "ESTADOS ANORMAIS OU PATOLÓGICOS NÃO PREVISTOS",
    },
    {
      param: "total",
      label: "TOTAL",
    },
  ];

  const handleSave = async (e) => {
    e.preventDefault();
    const response = await Swal.fire({
      title: "Configurações da planilha SIF",
      text: "Deseja salvar alterações?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0451e8",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, Salvar!",
      showLoaderOnConfirm: true,
      preConfirm: async () =>
        await api
          .put("broilersifconfig/" + props.customer._id, sifConfig)
          .catch((err) => ({
            err: true,
            data: { message: err.response.data.message },
          })),
    });
    if (response.value) {
      Swal.fire(
        "Histórico de Produção de Frango de Corte",
        response.value.data.message,
        response.value.err ? "error" : "success"
      );
    }
  };

  useEffect(() => {
    const getSifConfig = async () => {
      const response = await api.get("broilersifconfig/" + props.customer._id);
      if (response.data) {
        setSifConfig(response.data);
      }
    };
    if (loadSifConfig) {
      setLoadSifConfig(false);
      getSifConfig();
    }
  }, [props.customer?._id, loadSifConfig]);

  return (
    <Block className="animate__animated animate__fadeInUp">
      <BlockHeader>
        <Tabs
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          onChange={(e, value) => {
            setTab(value);
          }}
        >
          <Tab label="Abate" />
        </Tabs>
      </BlockHeader>
      <BlockBody>
        {tab === 0 && (
          <form onSubmit={handleSave}>
            <Row>
              <Col>
                <h3>Planilha SIF</h3>
              </Col>
            </Row>

            <Row>
              <Col>
                <h3>Linhas</h3>
                <Divider />
              </Col>
            </Row>

            <Row>
              <Col>
                <Input
                  label="Cabeçalho"
                  item={sifConfig}
                  setItem={setSifConfig}
                  params="header"
                  type="input"
                  inputType="number"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <h3>Colunas</h3>
                <Divider />
              </Col>
            </Row>

            <Row>
              <Col>
                <Input
                  label="Lote"
                  item={sifConfig}
                  setItem={setSifConfig}
                  params="lot"
                  type="input"
                  capitalize
                />
              </Col>
              <Col>
                <Input
                  label="Total de aves"
                  item={sifConfig}
                  setItem={setSifConfig}
                  params="totalSlaughter"
                  type="input"
                  capitalize
                />
              </Col>
              <Col>
                <Input
                  label="Data da vista"
                  item={sifConfig}
                  setItem={setSifConfig}
                  params="date"
                  type="input"
                  capitalize
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Table>
                  <Header>
                    <tr>
                      <th style={{ width: "400px" }}></th>
                      <th>
                        <span>Condenação Total</span>
                      </th>
                      <th>
                        <span>Condenação Parcial</span>
                      </th>
                      <th>
                        <span>Aproveitamento Condicional</span>
                      </th>
                    </tr>
                  </Header>
                  <Body>
                    {tableConfig.map(({ param, label }) => (
                      <tr key={param}>
                        <td>{label}</td>
                        <td>
                          <Input
                            type="inputOnly"
                            params={`${param}TotalCondemnation`}
                            item={sifConfig}
                            setItem={setSifConfig}
                            placeholder="Cond. total"
                            capitalize
                          />
                        </td>
                        <td>
                          <Input
                            type="inputOnly"
                            params={`${param}PartialCondemnation`}
                            item={sifConfig}
                            setItem={setSifConfig}
                            placeholder="Cond. parcial"
                            capitalize
                          />
                        </td>
                        <td>
                          <Input
                            type="inputOnly"
                            params={`${param}ConditionalUse`}
                            item={sifConfig}
                            setItem={setSifConfig}
                            placeholder="Apr. Condicional"
                            capitalize
                          />
                        </td>
                      </tr>
                    ))}
                  </Body>
                </Table>
              </Col>
            </Row>
            <Row>
              <Col style={{ alignItems: "center" }}>
                <Button
                  type="submit"
                  bg="default"
                  border="default"
                  color="white"
                >
                  Salvar
                  <Icons type={"save"} color={"white"} size={18} />
                </Button>
              </Col>
            </Row>
          </form>
        )}
      </BlockBody>
    </Block>
  );
};
